<template>
  <div class="upgrade-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="版本名称：">
        <el-input
          oninput="if (value.length>5) value=value.slice(0,30)"
          v-model="searchForm.versionName"
          placeholder="请输入版本名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="柜机编码：">
        <el-input
          oninput="if (value.length>5) value=value.slice(0,20)"
          v-model="searchForm.lockerCode"
          placeholder="请输入柜机编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="同类柜机升级" prop="deviceMainSub">
        <el-select v-model="searchForm.universal" placeholder="请选择" size="small">
          <el-option
            v-for="item in universalOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预设升级时间：" label-width="120px">
        <el-date-picker
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="upgradeTime"
          @change="monitorTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="success" size="small" @click="newlyAdded">新增</el-button>
    </el-row>
    <Table
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <template #startTimeStrHeader>
        <span>预设升级时间</span>
        <el-tooltip class="item" effect="dark" placement="top-start">
          <div slot="content">
            请注意：<br />1.所设置的预设升级时间在执行时会转换成当地对应相同的时间点升级;
            <br />2.如已有一台柜机在当地规定时间点进行升级后就无法停止该升级服务，
            <br />所以可能存在任务提前开始无法停止任务;
          </div>
          <i class="el-icon-question default-color"></i>
        </el-tooltip>
      </template>
      <el-link
        type="primary"
        slot="lockerBindCount"
        slot-scope="{ originScope }"
        @click="handleClickLockerBindCount(originScope.row)"
      >
        {{ originScope.row.lockerBindCount }}
      </el-link>
      <template #universal="{ originScope }">
            <span>{{ originScope.row.universal === 2 ? '是': '否' }}</span>
          </template>
      <template #status="{ originScope }">
        {{ originScope.row.status === 0 ? '未开始' : originScope.row.status === 1 ? '进行中' : '已结束' }}
      </template>
      <el-table-column slot="operate" label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button style="color: #77b922" type="text" size="small" @click="handleSee(scope.row)">查看</el-button>
          <el-button
            type="text"
            size="small"
            :disabled="scope.row.status !== 0"
            :style="{ color: scope.row.status !== 0 ? '#808080' : '#77b922' }"
            @click="handleStop(scope.row)"
            >停止</el-button
          >
        </template>
      </el-table-column>
    </Table>

    <!-- 柜机列表 -->
    <Dialog width="978px" title="柜机列表" :visible="cabinetVisible" @close="closeDialogcabinet">
      <div class="cabinet-dialog-wrap">
        <el-form
          size="small"
          ref="cabinetRuleForm"
          class="search-pannel"
          label-position="left"
          :model="cabinetForm"
          @submit.native.prevent
        >
          <el-form-item label="柜机编码：">
            <el-input
              placeholder="请输入柜机编码"
              oninput="if (value.length>5) value=value.slice(0,20)"
              v-model="cabinetForm.lockerCode"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属企业：">
            <el-input
              placeholder="请输入所属企业"
              oninput="if (value.length>5) value=value.slice(0,30)"
              v-model="cabinetForm.customerName"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属国家：">
            <el-select clearable placeholder="请选择所属国家" v-model="cabinetForm.areaCode" @change="changeactType">
              <el-option
                v-for="item in areaCodeList"
                :key="item.areaCode"
                :label="item.areaName"
                :value="item.areaCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="升级结果：">
            <el-select
              clearable
              placeholder="请选择升级结果"
              v-model="cabinetForm.upgradeStatus"
              @change="changeactType"
            >
              <el-option v-for="item in upgradeList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="small" native-type="submit" @click="onSearchCabinet">搜索</el-button>
            <el-button size="small" @click="onResetCabinet('form')">重置</el-button>
          </el-form-item>
        </el-form>
        <Table
          style="margin-top: 30px"
          :header-cell-style="{ background: '#F7F8FA ' }"
          :columns="columnsCabine"
          :data="tableCabinetData"
          :total="pageCabinetParams.total"
          :page="pageCabinetParams.pageNum"
          :limit="pageCabinetParams.pageSize"
          :hidden="!tableCabinetData.length"
          @pagination="updatePageCabinet"
        >
          <template #upgradeStatus="{ originScope }">
            <span :class="[originScope.row.upgradeStatus === 2 || originScope.row.upgradeStatus === 3 ? 'red' : '']">
              {{
                originScope.row.upgradeStatus === 1
                  ? '成功'
                  : (originScope.row.upgradeStatus === 2
                  ? '失败'
                  : (originScope.row.upgradeStatus === 3
                  ? '人为停止'
                  : '无'))
              }}{{ originScope.row.upgradeStatus === 2 ? `-${ originScope.row.failReason || '未知' }` : '' }}
            </span>
          </template>
        </Table>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
// import Select from '@/components/Select'
import Dialog from '@/components/Dialog'
import { getTaskPageList, getUpgradeLockerPageList, getcountryList, stopTask } from '@/api/modules/operation'
export default {
  // 柜机版本升级
  name: 'versionUpgrade',
  components: {
    Table,
    // Select,
    Dialog
  },
  data() {
    return {
      searchForm: {
        versionName: '',
        lockerCode: '',
        startTimeStrBegin: '',
        startTimeStrEnd: '',
        universal: ''
      },
      cabinetForm: {
        lockerCode: '',
        customerName: '',
        areaCode: '',
        upgradeStatus: ''
      },
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      tableData: [],
      columns: [
        { label: '版本名称', prop: 'versionName' },
        { label: '柜机列表', prop: 'lockerBindCount' },
        { label: '同类柜机升级', prop: 'universal' },
        { label: '任务状态', prop: 'status' }, // 0-未开始；1-进行中；2-已结束
        { label: '预设升级时间', prop: 'startTimeStr' },
        { label: '创建时间', prop: 'createSysTm' },
        { label: '创建人', prop: 'createUserName' }
      ],
      columnsCabine: [
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '柜机类型', prop: 'lockerTypeName' },
        { label: '所属企业', prop: 'customerName' },
        { label: '所属国家', prop: 'areaName' },
        { label: '升级结果', prop: 'upgradeStatus' }
      ],
      upgradeTime: [],
      areaCodeList: [],
      tableLoading: false,
      cabinetVisible: false,
      // // 0-无；1-成功；2-失败；3-人为停止
      upgradeList: [
        { value: 0, label: '无' },
        { value: 1, label: '成功' },
        { value: 2, label: '失败' },
        { value: 3, label: '人为停止' }
      ],
      cStyle: { width: '280px' },
      tableCabinetData: [],
      pageCabinetParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      versionId: '',
      universalOptions: [{
        label: '否',
        value: 1
      }, {
        label: '是',
        value: 2
      }]
    }
  },
  created() {
    this.onSearch(true)
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    getCabinetParams(flag) {
      const getParam = { ...this.cabinetForm }
      getParam.pageNum = flag ? 1 : this.pageCabinetParams.pageNum
      getParam.pageSize = this.pageCabinetParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getTaskPageList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.upgradeTime = []
      this.searchForm = {
        versionName: '',
        lockerCode: '',
        startTimeStrBegin: '',
        startTimeStrEnd: ''
      }
    },
    handleSee(row) {
      this.$router.push({ path: '/operation-manage/version-upgrade/new-cabinet', query: { id: row.id } })
    },
    handleStop(row) {
      this.$confirm('请确定是否要停止升級任务', '确认')
        .then(async() => {
          const result = await stopTask({ upgradeTaskId: row.id })
          result.code === '000000000' && this.$message.success(`${result.msg}`)
          this.onSearch(false)
        })
        .catch()
    },
    // 新增
    newlyAdded() {
      console.log('newlyAdded: ')
      this.$router.push({ path: '/operation-manage/version-upgrade/new-cabinet' })
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    updatePageCabinet(val) {
      if (val) {
        this.pageCabinetParams.pageNum = val.page
        this.pageCabinetParams.pageSize = val.limit
        this.onSearchCabinet()
      }
    },
    // 监听时间变化
    monitorTime(val) {
      this.searchForm.startTimeStrBegin = val[0]
      this.searchForm.startTimeStrEnd = val[1]
      this.onSearch()
    },
    // 柜机列表
    handleClickLockerBindCount(row) {
      this.cabinetVisible = true
      this.versionId = row.id
      this.getcountry()
      this.onSearchCabinet()
    },
    closeDialogcabinet() {
      this.cabinetVisible = false
    },
    onSearchCabinet(flag) {
      const data = {
        upgradeTaskId: this.versionId,
        ...this.getCabinetParams(flag)
      }
      getUpgradeLockerPageList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableCabinetData = records || []
          this.pageCabinetParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          // this.tableLoading = false
        })
    },
    onResetCabinet(formName) {
      this.cabinetForm = {
        lockerCode: '',
        customerName: '',
        areaCode: '',
        upgradeStatus: ''
      }
      this.onSearchCabinet()
    },
    // 获取城市列表
    getcountry(fn, type) {
      getcountryList().then((res) => {
        this.areaCodeList = res.data || []
        this.areaCodeList.forEach((item) => {
          item.value = item.areaCode
          item.label = item.areaName
        })
      })
    },
    // 处理select
    changeactType() {
      this.onSearchCabinet()
    }
  }
}
</script>

<style lang="scss" scoped>
.upgrade-wrap {
  padding: 24px;
}
.red {
  color: red;
}
.default-color {
  color: #c8c9cc;
  margin-left: 5px;
  font-size: 18px;
}
</style>
