import { axios1 } from '../instance'

/* 运维管理 */

// 操作日志

/**
 * 操作日志列表
 * @param {object} data
 * @param {string} data.requestTime 请求时间
 * @param {string} data.requestId  请求id 随机生成id
 * @param {string} data.platformId 平台id 001
 * @param {object} data.data
 * @param {string} data.data.activityType 1.创建柜机2设备管理3.可视化模板4.登录柜机5.柜机激活n6.操作柜机7远程控制8.整机故障9.联网日志10.编辑柜机11.删除柜机12.状态变更
 * @param {string} data.data.lockerCode 柜机编码 FC0000000001
 * @param {string} data.data.operatorName 操作人姓名
 * @param {string} data.data.startTime 操作开始时间
 * @param {string} data.data.endTime  操作结束时间
 * @param {string} data.data.pageNum  页码
 * @param {string} data.data.pageSize  页数
 * @return {Promise}
 */
export const getActivityLog = (data) => {
  return axios1.postByJson('/logger/activityLog/list', data)
}

// 异常日志

/**
 * 异常日志列表
 * @param {object} data
 * @param {string} data.requestTime 请求时间
 * @param {string} data.requestId  请求id 随机生成id
 * @param {string} data.platformId 平台id 001
 * @param {object} data.data
 * @param {string} data.data.abnormalType 异常类型1.格口2.柜机信号丢失3.柜机激活
 * @param {string} data.data.startTime 操作开始时间
 * @param {string} data.data.endTime  操作结束时间
 * @param {string} data.data.lockerSearch  柜机/格口搜案内容
 * @param {string} data.data.pageNum  页码
 * @param {string} data.data.pageSize  页数
 * @return {Promise}
 */
export const getAbnormal = (data) => {
  return axios1.postByJson('/logger/abnormal/list', data)
}

// 版本管理

/**
 * 新增版本管理
 * @param {object} data
 * @param {number} data.versionNo  版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.applyType  适用柜机类型code(多个用逗号“,”隔开)
 * @param {string} data.applyName  适用柜机名称(多个用逗号“,”隔开)
 * @param {string} data.url   包全路径
 * @param {string} data.upgradeApplyCode   升级应用code
 * @param {string} data.upgradeApplyName   升级应用名称
 * @param {string} data.md5   md5值
 * @return {Promise}
 */
export const addVersion = (data) => {
  return axios1.postByJson('/ota/version/add', data)
}

/**
 * 编辑版本管理
 * @param {object} data
 * @param {number} data.id 版本管理主键
 * @param {number} data.versionNo  版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.applyType  适用柜机类型code(多个用逗号“,”隔开)
 * @param {string} data.applyName  适用柜机名称(多个用逗号“,”隔开)
 * @param {string} data.url   包全路径
 * @param {string} data.upgradeApplyCode   升级应用code
 * @param {string} data.upgradeApplyName   升级应用名称
 * @param {string} data.md5   md5值
 * @return {Promise}
 */
export const editVersion = (data) => {
  return axios1.postByJson('/ota/version/edit', data)
}

/**
 * 版本列表
 * @param {object} data
 * @param {number} data.versionNo  版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.applyType  适用柜机类型code(多个用逗号“,”隔开)
 * @param {string} data.upgradeApplyCode   升级应用code
 * @return {Promise}
 */
export const getVersionList = (data) => {
  return axios1.postByJson('/ota/version/versionPageList', data)
}

/**
 * 获取版本下对应的柜机分页列表(柜机列表)
 * @param {object} data
 * @param {number} data.versionId   版本管理主键（id）
 * @param {number} data.lockerCode   柜机编码
 * @param {number} data.customerCode   企业编码
 * @param {number} data.areaCode   所属国家code
 * @param {number} data.upgreadeStartTime   开始查询时间
 * @param {number} data.upgreadeEndTime   结束查询时间
 * @return {Promise}
 */
export const getLockerPageList = (data) => {
  return axios1.postByJson('/ota/version/getLockerPageList', data)
}

/**
 * 获取国家列表
 * @return {Promise}
 */
export const getcountryList = (data) => {
  return axios1.get('/ota/upgradeTask/upgrade/locker/query/countryList', data)
}

// 版本升级

/**
 * 版本升级列表
 * @param {object} data
 * @param {number} data.versionId  版本id
 * @param {string} data.versionName   版本名称
 * @param {string} data.lockerCode  柜机编码
 * @param {string} data.startTimeStrBegin   预设升级时间-开始
 * @param {string} data.startTimeStrEnd   预设升级时间-结束
 * @return {Promise}
 */
export const getTaskPageList = (data) => {
  return axios1.postByJson('/ota/upgradeTask/taskPageList', data)
}

/**
 * 对应的柜机分页列表
 * @param {object} data
 * @param {number} data.versionId  版本id
 * @param {string} data.lockerCode  柜机编码
 * @param {string} data.customerCode  企业编码
 * @param {string} data.areaCode  所属国家code
 * @param {string} data.upgreadeStartTime  升级时间(开始查询时间)
 * @param {string} data.upgreadeEndTime  升级时间(开始查询时间)
 * @param {number} data.upgradeTaskId   升级任务id
 * @param {number} data.upgradeStatus    0-无；1-成功；2-失败；3-人为停止
 * @return {Promise}
 */
export const getUpgradeLockerPageList = (data) => {
  return axios1.postByJson('/ota/version/getLockerPageList', data)
}

/**
 * 版本管理列表搜索（新增）
 * @param {object} data
 * @param {string} data.versionName   版本名称
 * @return {Promise}
 */
export const seeVersionList = (data) => {
  return axios1.postByJson('/ota/version/versionList', data)
}

/**
 * 新增版本升级任务（新增）
 * @param {object} data
 * @param {number} data.versionId  版本id
 * @param {string} data.versionNo   版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.startTimeStr   预设升级时间（yyyy-MM-dd HH:mm:ss格式）
 * @param {Arrar} data.lockerBindReqList    绑定柜机集合
 * @param {lockerBindReqList-string} lockerCode    柜机编码
 * @param {lockerBindReqList-string} lockerType     柜机类型code
 * @param {lockerBindReqList-string} lockerTypeName     柜机类型名称
 * @param {lockerBindReqList-string} customerCode     客户编码
 * @param {lockerBindReqList-string} customerName     客户名称
 * @param {lockerBindReqList-string} areaCode    所属国家code
 * @param {lockerBindReqList-string} areaName     所属国家
 * @param {lockerBindReqList-string} version      现有版本名称
 * @return {Promise}
 */
export const addUpgradeTask = (data) => {
  return axios1.postByJson('/ota/upgradeTask/add', data)
}

/**
 * 新增版本升级可选柜机/已选柜机的列表（新增）
 * @param {object} data
 * @param {string} data.lockerCode  柜机编码
 * @param {string} data.productCode  产品类型
 * @param {string} data.customerName  所属企业
 * @param {string} data.country  所属国家
 * @param {Array} data.selectedLockerCodes  已选柜机列表，查询已选柜机时携带
 * @param {Boolean} data.isSelected  查询已选柜机为true,可选柜机为False
 * @return {Promise}
 */
export const upgradeTaskQuery = (data) => {
  return axios1.postByJson('/ota/upgradeTask/upgrade/locker/query', data)
}

/**
 * 版本升级任务详情（详情）
 * @param {object} data
 * @param {string} data.upgradeTaskId  升级任务id
 * @return {Promise}
 */
export const taskDetail = (params) => {
  return axios1.get('/ota/upgradeTask/taskDetail', { params })
}

/**
 * 停止版本升级
 * @param {object} data
 * @param {number} data.upgradeTaskId   升级任务id
 * @return {Promise}
 */
export const stopTask = (data) => {
  return axios1.postByJson('/ota/upgradeTask/stopTask', data)
}

// 移动端升级

/**
 * 移动端升级任务分页列表
 * @param {object} data
 * @param {number} data.versionNo   版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.upgradeApplyCode   升级应用code
 * @param {string} data.systemCode   应用系统code
 * @param {string} data.validTime   生效时间
 * @param {string} data.createTime   创建时间
 * @return {Promise}
 */
export const getMobilePageTaskList = (data) => {
  return axios1.postByJson('/ota/upgradeTask/mobile/getMobilePageTaskList', data)
}

/**
 * 移动端新增升级
 * @param {object} data
 * @param {number} data.versionNo   版本编号
 * @param {string} data.versionName   版本名称
 * @param {string} data.startTimeStr   预设生效时间 如果是立即生效，则不传
 * @param {string} data.upgradeApplyCode    升级应用code
 * @param {string} data.upgradeApplyName   升级应用名称
 * @param {number} data.forceFlag   是否是强制更新 1-是 2-否
 * @param {string} data.systemCode   应用系统code 1-iOS 2-Android
 * @param {string} data.systemName   应用系统名称 1-iOS 2-Android
 * @param {number} data.immediateEffectFlag    1-立即生效 2-不立即生效
 * @param {Array} data.mobileUpgradeLangConfigList   语言描述集合
 * @param {Array} data.mobileUpgradeLangConfigList.langCode   语言code
 * @param {Array} data.mobileUpgradeLangConfigList.langName   语言名称
 * @param {Array} data.mobileUpgradeLangConfigList.upgradeDesc   描述
 * @return {Promise}
 */
export const addMobileUpgrade = (data) => {
  return axios1.postByJson('/ota/upgradeTask/mobile/save', data)
}
