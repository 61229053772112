<template>
  <div>
    <el-table
      border
      ref="tableRef"
      size="small"
      v-bind="$attrs"
      v-on="$listeners"
      v-loading="loading"
      :data="data"
      :header-cell-class-name="handleHeaderClass"
      :header-cell-style="headerCellStyle"
      @header-click="handleHeaderCLick"
      @sort-change="handleTableSort"
    >
      <slot name="index"></slot>
      <slot name="selection"></slot>
      <el-table-column v-for="col of columnsShouldShow" :key="col.prop" v-bind="{'min-width':col.width||150, 'show-overflow-tooltip':true, ...col }">
        <template #header="scope">
          <slot v-if="$scopedSlots[`${col.prop}Header`]" :name="`${col.prop}Header`" :originScope="scope" />
          <div style="display: inline-block" v-else>
            {{ col.label }}
          </div>
        </template>
        <template #default="scope">
          <slot v-if="$scopedSlots[col.prop]" :name="col.prop" :originScope="scope" />
          <template v-else>
            {{ scope.row[col.prop] | defaultPropValue}}
          </template>
        </template>
      </el-table-column>
      <!-- <template #empty>
        <slot name="customEmpty" >
          <el-empty :image-size="45" :image="require('@/assets/images/table-empty.png')"></el-empty>
        </slot>
      </template> -->
      <div slot="empty" style="padding: 190px">
        <img style="width: 60px" src="@/assets/images/table-empty.png" alt="empty table" />
        <div style="line-height: 20px">暂无数据</div>
      </div>
      <slot name="operate" />
    </el-table>
    <div :class="{'hidden':hidden,'isright':isright}" class="pagination-container">
      <el-pagination
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      </div>
  </div>
</template>

<script>
import { defaultPropValue } from '@/utils'
export default {
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    tableConfig: {
      type: Object,
      default: () => {}
    },
    total: {
      required: true,
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    isright: {
      type: Boolean,
      default: true
    }
  },
  filters: { defaultPropValue },
  data() {
    return {}
  },
  computed: {
    /**
     * 计算出需要显示的列
     */
    columnsShouldShow() {
      return this.columns.filter((i) => !i.hidden)
    },
    // 处理分页
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  mounted() {
    // console.log('this', this)
    // console.log('this.$slots', this.$slots)
  },
  methods: {
    handleHeaderClass({ column }) {
      column.order = column.multiOrder
    },
    headerCellStyle() {
      return 'color: #323233'
    },
    handleHeaderCLick(column, event) {
      // console.log('column, event: ', column, event)
      if (column.sortable !== 'custom') {
        return
      }
      if (!column.multiOrder) {
        column.multiOrder = 'ascending'
      } else if (column.multiOrder === 'ascending') {
        column.multiOrder = 'descending'
      } else {
        column.multiOrder = null
      }
      this.updateSortStatus(column.property, column.multiOrder)
    },
    handleTableSort({ column, prop, order }) {
      if (column.sortable === 'custom') {
        column.multiOrder = order
        this.updateSortStatus(column.property, column.multiOrder)
      }
    },
    updateSortStatus(prop, order) {
      const find = this.columns.find((i) => i.prop === prop)
      if (find) {
        find.order = order
      }
    },
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  padding: 24px 0;
}
.pagination-container.hidden {
  display: none;
}
.pagination-container.isright{
  text-align: end;
}
.custom-header{
  background: red!important;
}
</style>
